import styled from "styled-components";
import Input from "../Input/Input";
import {useEffect, useRef, useState} from "react";
import RequiredText from '../RequiredText/RequiredText';
import {ReactComponent as Trash} from '../../assets/icons/trash.svg';
import Label from '../Label/Label';
import Select from '../Select/Select';
import {MultiSelectField} from './MutliSelectField';

const StyledSupplierField = styled.div``;

const HiddenInput = styled.input`
    display: none;
`;

const Row = styled.div`
    display: flex;
    gap: 12px;
    margin-top: 10px;

    @media screen and (max-width: 580px) {
        gap: 6px
    }
`;

const InnerRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 20px;
    border-radius: 6px;
    background-color: var(--color-supplier-field-background);
    max-width: calc(100% - 36px); // gap of Row + width of TrashIcon 

    @media screen and (max-width: 580px) {
        max-width: calc(100% - 26px);
    }
`;

const InnerRowFlex = styled.div`
    display: flex;
    gap: 12px;
    ${({$end}) => $end && 'align-items: flex-end;'};
    
    @media screen and (max-width: 768px) {
        ${({$end}) => $end && `
            flex-direction: column;
            gap: 20px;
        `};
    }

    @media screen and (max-width: 580px) {
        gap: ${({$end}) => $end ? '20px' : '6px'};
    }
`;

const Field = styled.div`
    ${({$name}) => $name && 'flex: 1 1 400px;'};
    ${({$postalCode}) => $postalCode && 'flex: 1 1 250px;'};
    ${({$houseNumber}) => $houseNumber && 'flex: 1 1 100px;'};
    ${({$half}) => $half && 'flex: 1 1 0;'};
    ${({$other}) => $other && 'margin: 10px 0 0 30px;'};
`;

const SubLabel = styled(Label)`
    font-size: var( --fs-label-small);
`;

const StyledInput = styled(Input)`
    margin-top: 4px;
`;

const StyledSelect = styled(Select)`
    margin-top: 4px;
    background-color: var(--color-white);
`;

const IconWrapper = styled.div`
    margin-top: 50px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TrashIcon = styled(Trash)`
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
        path { fill: var(--color-green-60); }
    }

    &:active {
        path { fill: var(--color-green-70); }
    }

    @media screen and (max-width: 580px) {
        width: 20px;
        height: 20px;
    }
`;

const EmptyIcon = styled.div`
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    
    @media screen and (max-width: 580px) {
        width: 20px;
        height: 20px;
    }
`;

const AddNewRow = styled.button`
    margin-top: 20px;
    background: var(--color-green);
    color: var(--color-white);
    font-family: var(--font-mulish);
    font-weight: var(--fw-bold);
    font-size: var(--fs-button);
    border-radius: 10px;
    padding: 12px;
    display: inline-block;
    border: none;
    cursor: pointer;
    
    &:hover {
        background-color: var(--color-green-60);
    }
    
    &:active {
        background-color: var(--color-green-70);
    }
`;

const StyledRequiredText = styled(RequiredText)`
    margin-top: 10px;
`;

const vehicleTypeOptions = [
    { id: "Fiets", label: "Fiets" },
    { id: "Personenauto", label: "Personenauto" },
    { id: "Vrachtfiets (2 of 3 wielen)", label: "Vrachtfiets (2 of 3 wielen)" },
    { id: "Licht Elektrisch Vracht Voertuig (LEVV) (3 of 4 wielen)", label: "Licht Elektrisch Vracht Voertuig (LEVV) (3 of 4 wielen)" },
    { id: "Bestelwagen klein", label: "Bestelwagen klein" },
    { id: "Bestelwagen groot", label: "Bestelwagen groot" },
    { id: "Bakwagen", label: "Bakwagen" },
    { id: "Citytrailer", label: "Citytrailer" },
    { id: "Trekker-oplegger combinatie", label: "Trekker-oplegger combinatie" },
    { id: "Vuilniswagen", label: "Vuilniswagen" },
    { id: "Anders namelijk", label: "Anders namelijk" }
];

const SupplierField = ({children, ...props}) => {
    const baseObj = {
        name: "",
        postalCode: "",
        houseNumber: "",
        mostCommonUnit: "",
        avgWeightPerLoadingUnit: "",
        loadingUnitsPerWeek: "",
        whenWillReceiptsTakePlace: "",
        transportCarrier: "",
        logisticsServiceProvider: "",
        vehicleType: [],
        vehicleTypeOther: "",
        alternativeFuels: "",
    };

    // The previous props.value was an array of arrays, now it's an array of objects
    const initialSupplierData = (Array.isArray(props?.value?.[0]) || !props?.value) ? [baseObj] : JSON.parse(props.value);

    const [supplierData, setSupplierData] = useState(initialSupplierData);

    const handleChange = (index, e) => {
        const {name, value} = e.target;

        setSupplierData(prevSupplierData => {
            const updatedSupplierData = [...prevSupplierData];
            updatedSupplierData[index] = {
                ...updatedSupplierData[index],
                [name]: value
            };
            return updatedSupplierData.filter(Boolean);
        });
    }

    const handleDeleteRow = (index) => {
        const updatedSupplierData = [...supplierData];
        if (!updatedSupplierData[index]) updatedSupplierData[index] = baseObj;
        delete updatedSupplierData[index];
        setSupplierData(updatedSupplierData.filter(Boolean));
    }

    const handleAddNewRow = () => {
        const updatedSupplierData = [...supplierData];
        updatedSupplierData.push(baseObj);
        setSupplierData(updatedSupplierData.filter(Boolean));
    }

    const handleVehicleTypeChange = (index, action, value) => {
        const updatedSupplierData = [...supplierData];
        const vehicleTypeArray = updatedSupplierData[index]?.vehicleType;

        switch (action) {
            case "add":
                if (!vehicleTypeArray?.includes(value)) {
                    vehicleTypeArray.push(value);
                }
                break;
            case "remove":
                updatedSupplierData[index].vehicleType = vehicleTypeArray.filter(item => item !== value);
                break;
            default:
                break;
        }

        setSupplierData(updatedSupplierData.filter(Boolean));
    };

    const isMounted = useRef(false);
    useEffect(() => {
        if (isMounted?.current) {
            props.setValue(props.name, JSON.stringify(supplierData), { shouldValidate: true });
            props.fieldBlur();

            // Validate for required field if only first/default row exists and most important fields are empty
            if(supplierData.length <= 1 && (supplierData[0]["name"] === "" || supplierData[0]["postalCode"] === "" || supplierData[0]["houseNumber"] === "" || supplierData[0]["avgWeightPerLoadingUnit"] === "" || supplierData[0]["loadingUnitsPerWeek"] === "")) {
                props.setValue(props.name, "", { shouldValidate: true });
                props.fieldBlur();
            }
        }

        isMounted.current = true;
        //eslint-disable-next-line
    }, [supplierData]);

    return (
        <StyledSupplierField>
            <HiddenInput
                type="text"
                defaultValue={props.value}
                {...props.register(props.name, {
                    required: props.required
                })}
            />

            {(Array.isArray(supplierData) && supplierData.length > 0) && supplierData.map((supplier, index) => (
                <Row key={index}>
                    <InnerRow>
                        <InnerRowFlex>
                            <Field $name>
                                <SubLabel>Naam</SubLabel>
                                <StyledInput
                                    type="text"
                                    name="name"
                                    value={supplier.name}
                                    onChange={(e) => handleChange(index, e)}
                                />
                            </Field>
                            <Field $postalCode>
                                <SubLabel>Postcode</SubLabel>
                                <StyledInput
                                    type="text"
                                    name="postalCode"
                                    value={supplier.postalCode}
                                    onChange={(e) => handleChange(index, e)}
                                />
                            </Field>
                            <Field $houseNumber>
                                <SubLabel>Huisnr.</SubLabel>
                                <StyledInput
                                    type="text"
                                    name="houseNumber"
                                    value={supplier.houseNumber}
                                    onChange={(e) => handleChange(index, e)}
                                />
                            </Field>
                        </InnerRowFlex>

                        <Field>
                            <SubLabel>Wat is het meest gangbare type (laad)eenheid dat wordt ontvangen?</SubLabel>
                            <StyledSelect
                                name="mostCommonUnit"
                                value={supplier.mostCommonUnit}
                                onChange={(e) => handleChange(index, e)}
                            >
                                <option value="">Selecteer</option>
                                <option value="Kilogram">Kilogram</option>
                                <option value="Rolcontainer">Rolcontainer</option>
                                <option value="Pallet">Pallet</option>
                                <option value="Losse dozen/kratten/vaten">Losse dozen/kratten/vaten</option>
                                <option value="Post & Pakketten">Post & Pakketten</option>
                                <option value="Kledingrekken">Kledingrekken</option>
                                <option value="Maaltijden">Maaltijden</option>
                            </StyledSelect>
                        </Field>

                        <InnerRowFlex $end>
                            <Field $half>
                                <SubLabel>Wat is het gemiddelde gewicht in kilogram van de (laad)eenheid? (vul 1 in als voor de (laad)eenheid “kilogram” is gekozen)</SubLabel>
                                <StyledInput
                                    type="number"
                                    name="avgWeightPerLoadingUnit"
                                    value={supplier.avgWeightPerLoadingUnit}
                                    onChange={(e) => handleChange(index, e)}
                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                />
                            </Field>
                            <Field $half>
                                <SubLabel>Wat is de hoeveelheid van de (laad)eenheid per week?</SubLabel>
                                <StyledInput
                                    type="number"
                                    name="loadingUnitsPerWeek"
                                    value={supplier.loadingUnitsPerWeek}
                                    onChange={(e) => handleChange(index, e)}
                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                />
                            </Field>
                        </InnerRowFlex>

                        <Field>
                            <SubLabel>Wanneer vinden de ontvangsten plaats?</SubLabel>
                            <StyledSelect
                                name="whenWillReceiptsTakePlace"
                                value={supplier.whenWillReceiptsTakePlace}
                                onChange={(e) => handleChange(index, e)}
                            >
                                <option value="">Selecteer</option>
                                <option value="Maandag">Maandag</option>
                                <option value="Dinsdag">Dinsdag</option>
                                <option value="Woensdag">Woensdag</option>
                                <option value="Donderdag">Donderdag</option>
                                <option value="Vrijdag">Vrijdag</option>
                                <option value="Zaterdag">Zaterdag</option>
                                <option value="Zondag">Zondag</option>
                            </StyledSelect>
                        </Field>

                        <Field>
                            <SubLabel>Wie voert het transport uit?</SubLabel>
                            <StyledSelect
                                name="transportCarrier"
                                value={supplier.transportCarrier}
                                onChange={(e) => handleChange(index, e)}
                            >
                                <option value="">Selecteer</option>
                                <option value="Logistiek dienstverlener">Logistiek dienstverlener</option>
                                <option value="Leverancier met eigen vervoer">Leverancier met eigen vervoer</option>
                            </StyledSelect>
                        </Field>

                        <Field>
                            <SubLabel>Indien er sprake is van een logistiek dienstverlener. Wat is de naam van de logistiek dienstverlener?</SubLabel>
                            <StyledInput
                                type="text"
                                name="logisticsServiceProvider"
                                value={supplier.logisticsServiceProvider}
                                onChange={(e) => handleChange(index, e)}
                            />
                        </Field>

                        <Field>
                            <SubLabel>Welk type voertuig wordt ingezet?</SubLabel>
                            <MultiSelectField
                                data={vehicleTypeOptions}
                                value={supplier.vehicleType}
                                handleOnChange={(value, action) => handleVehicleTypeChange(index, action, value)}
                            />

                            {supplier?.vehicleType?.includes("Anders namelijk") &&
                                <Field $other>
                                    <SubLabel>Anders namelijk</SubLabel>
                                    <StyledInput
                                        type="text"
                                        name="vehicleTypeOther"
                                        value={supplier.vehicleTypeOther}
                                        onChange={(e) => handleChange(index, e)}
                                    />
                                </Field>
                            }
                        </Field>

                        <Field>
                            <SubLabel>Wordt er gebruik gemaakt van alternatieve brandstoffen?</SubLabel>
                            <StyledSelect
                                name="alternativeFuels"
                                value={supplier.alternativeFuels}
                                onChange={(e) => handleChange(index, e)}
                            >
                                <option value="">Selecteer</option>
                                <option value="Ja, elektrisch">Ja, elektrisch</option>
                                <option value="Ja, waterstof">Ja, waterstof</option>
                                <option value="Ja, HVO (blauwe diesel)">Ja, HVO (blauwe diesel)</option>
                                <option value="Ja, andere alternatieve brandstof">Ja, andere alternatieve brandstof</option>
                                <option value="Nee, alleen fossiele brandstoffen">Nee, alleen fossiele brandstoffen</option>
                                <option value="Weet ik niet">Weet ik niet</option>
                            </StyledSelect>
                        </Field>
                    </InnerRow>
                    <IconWrapper>
                        {index !== 0 ? <TrashIcon onClick={() => handleDeleteRow(index)}/> : <EmptyIcon />}
                    </IconWrapper>
                </Row>
            ))}

            <AddNewRow type="button" onClick={handleAddNewRow}>+ Nieuwe rij toevoegen</AddNewRow>

            {props.errors && <StyledRequiredText>Dit veld is verplicht</StyledRequiredText>}
        </StyledSupplierField>
    )
}

export default SupplierField